<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="items">
          <div class="empty">
            <div>
              <img src="@/assets/cg.png" />
            </div>
            <div class="text">{{msg}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.msg = decodeURI(this.$route.query.message);
  },
  data() {
    return {
      msg: ''
    };
  }
};
</script>

<style lang="less" scoped>
.content {
  padding: 0px;
  margin-top: 40px;
}
.bg {
  height: 200px;
}
.empty {
  text-align: center;
  margin-top: 52px;
  margin-bottom: 52px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
